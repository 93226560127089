var PI_2 = 2 * Math.PI;
window.w = 0;
window.h = 0;

var xpos = 0.5;
document.onmousemove = function (e) {
  xpos = e.pageX / w;
};

var canvasHeight = 0, canvasWeight = 0;
//锁定长宽比，重设canvas长宽
function resizeHandel(width, height) {
  window.w = canvas.width = window.innerWidth;
  window.h = canvas.height = window.innerHeight;
  //小瓶的话背景会少点
  if (width <= 576) {
    resetConfetti(NUM_CONFETTI_SMALL);
  } else {
    resetConfetti(NUM_CONFETTI_LARGE);
  }
}

//基础函数
var range = function range(a, b) {
  return (b - a) * Math.random() + a;
};

//动态背景
var NUM_CONFETTI_LARGE = 26;
var NUM_CONFETTI_SMALL = 12;
var COLORS = [[255, 214, 90], [253, 70, 135], [255, 214, 90], [110, 188, 255], [253, 70, 135]];
var DRAW_TYPE = ["circle", "triangle", "sexangle"];
var canvas = null;
var context = null;

var canvas = document.getElementById("mainBackground");
var context = canvas.getContext("2d");


//画圆形
var drawCircle = function drawCircle(x, y, r, style) {
  context.beginPath();
  context.arc(x, y, r, 0, PI_2, false);
  context.fillStyle = style;
  context.fill();
};
//画三角形
var drawTriangle = function drawTriangle(x, y, r, angle, style) {
  var point2 = [0, 0], point3 = [0, 0];
  context.beginPath();
  point2[0] = x + r * Math.cos(angle);
  point2[1] = y - r * Math.sin(angle);
  point3[0] = x + r * Math.cos(angle + Math.PI / 3);
  point3[1] = y - r * Math.sin(angle + Math.PI / 3);
  context.moveTo(x, y);
  context.lineTo(point2[0], point2[1]);
  context.lineTo(point3[0], point3[1]);
  context.closePath();
  context.fillStyle = style;
  context.fill();
};
//画六边形
var drawSexangle = function drawSexangle(x, y, length, style) {
  var vertices = 6;
  context.beginPath();
  var px, py;
  var degree;
  for (var i = 0; i < vertices; i++) {
    // 计算偏转
    degree = i * Math.PI / 3;
    px = x + Math.cos(degree) * length;
    py = y - Math.sin(degree) * length;
    context.lineTo(px, py);
  }
  context.closePath();
  context.fillStyle = style;
  context.fill();
};

// 定义画图类
var Confetti = function () {
  this.style = COLORS[~~range(0, 5)];
  this.rgb = "rgba(" + this.style[0] + "," + this.style[1] + "," + this.style[2];

  //按屏幕大小缩放
  if (w <= 576) {
    this.r = ~~range(3, 5);
  } else if (w > 1366) {
    this.r = ~~range(4, 6);
  } else {
    this.r = ~~(range(4, 6) * w / 1366);
  }

  this.r2 = 2 * this.r;


  this.type = DRAW_TYPE[~~range(0, 3)];

  this.replace = function () {

    this.opacity = 0;
    this.dop = 0.005 * range(1, 4);
    this.x = range(-this.r2, w - this.r2);
    this.y = range(-20, h - this.r2);
    this.xmax = w - this.r;
    this.ymax = h - this.r;
    this.vx = range(0, 2);
    return this.vy = 0.7 * this.r + range(-1, 1);
  }
  this.draw = function () {
    this.x += (this.vx + 8 * xpos - 5) / 2;
    this.y += this.vy / 2.5;
    this.opacity += this.dop;
    if (this.opacity > 1) {
      this.opacity = 1;
      this.dop *= -1;
    }

    if (this.opacity < 0 || this.y > this.ymax) {
      this.replace();
    }
    if (!(0 < this.x && this.x < this.xmax)) {
      this.x = (this.x + this.xmax) % this.xmax;
    }
    //去掉透明
    if (this.type == DRAW_TYPE[0]) {
      drawCircle(~~this.x, ~~this.y, this.r, this.rgb + "," + this.opacity + ")");
    } else if (this.type == DRAW_TYPE[1]) {
      drawTriangle(~~this.x, ~~this.y, this.r * 3.0, 0, this.rgb + "," + this.opacity + ")");
    } else {
      drawSexangle(~~this.x, ~~this.y, this.r * 1.5, this.rgb + "," + this.opacity + ")");
    }
  }
  this.replace();
};

//生成图元
var confetti = [];

function resetConfetti(num) {
  confetti = __range__(1, num, true).map(function (i) {
    return new Confetti();
  });
}

function __range__(left, right, inclusive) {
  var range = [];
  var ascending = left < right;
  var end = !inclusive ? right : ascending ? right + 1 : right - 1;
  for (var i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
    range.push(i);
  }
  return range;
}


//进行画图
window.requestAnimationFrame = function () {
  return window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || window.oRequestAnimationFrame || window.msRequestAnimationFrame || function (callback) {
      return window.setTimeout(callback, 1000 / 60);
    };
}();

window.step = function () {
  requestAnimationFrame(step);
  context.clearRect(0, 0, w, h);
  //向下兼容，修改js
  // Array.from(confetti).map(function (c) {
  //     c.draw();
  // });
  for (var i in confetti) {
    confetti[i].draw();
  }
};

var initBackgroundCanvas = function initBackgroundCanvas(_canvas, _context) {

}


canvas = document.getElementById("mainBackground");
context = canvas.getContext("2d");
resizeHandel(window.innerWidth, window.innerHeight)
step();


window.onresize = function () {
  resizeHandel(window.innerWidth, window.innerHeight)
};

// document.body.addEventListener('touchmove', function (e) {
//     if (e._isScroller) return;
//     e.preventDefault();
// }, {passive: false});

